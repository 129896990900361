import { EventOut } from "../generated-sources/apiv1";

export function convertEventGetToFormData(
  event: EventOut,
  file: File
): FormData {
  let form = new FormData();
  console.log(event);
  Object.entries(event).forEach(([key, value]) => {
    console.log(key);
    if (key === "categories") {
      event.categories.forEach((category, index) => {
        if(category.id){
          console.log(category);
          form.append(`categories`, category.id.toString());
        }
      });
    } else if (key === "image") {
      if (file) {
        form.append(key, file, file.name);
      }
    } else if (value) {
      if (typeof value === "string") {
        form.append(key, value);
      } else {
        form.append(key, JSON.stringify(value));
      }
    }
  });
  return form;
}
