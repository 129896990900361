import { AppClientV1, EventOut } from "../generated-sources/apiv1";

export function postEventForm(
  appClient: AppClientV1,
  formData: FormData
): Promise<EventOut> {
  return appClient.request.request({
    method: "POST",
    url: "/api/v1/events/",
    body: formData,
  });
}

export function patchEventForm(
  appClient: AppClientV1,
  formData: FormData,
  eventId: string
): Promise<EventOut> {
  return appClient.request.request({
    method: "PATCH",
    url: `/api/v1/events/${eventId}`,
    body: formData,
  });
}

const configuration = {
  BASE: process.env.REACT_APP_BACKEND_URL,
};

export const appClient = new AppClientV1(configuration);
