import { Card, Placeholder } from "react-bootstrap";

function HorizontalCardPlaceholder() {
  return (
    <Card className="event-card">
      <div className="row no-gutter">
        <div className="col-4 order-last d-flex pe-4" style={{ padding: 5 }}>
          <Card.Img
            src="placeholder.JPG"
            style={{
              width: "auto",
              maxWidth: "100%",
              objectFit: "cover",
              borderRadius: "10px",
              margin: "2px",
            }}
          />
        </div>
        <div className="col-8">
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={12} />
            </Placeholder>{" "}
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
              <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
              <Placeholder xs={8} />
            </Placeholder>
            <Placeholder.Button variant="primary" xs={6} />
          </Card.Body>
        </div>
      </div>
    </Card>
  );
}

export default HorizontalCardPlaceholder;
