import { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../Components/Footer";
import { EventForm } from "../Components/Form";
import { EventOut } from "../generated-sources/apiv1";
import { postEventForm } from "../services/apiService";
import { useAppClientV1 } from "../util/appClient";
import { convertEventGetToFormData } from "../util/dataProcessing";
function CreateEvent() {
  const eventForm: EventOut = {
    id: 2,
    name: "",
    date_from: "",
    date_to: null,
    description: "",
    street: "",
    categories: [],
    city: "",
    country: "",
    image: "",
  };
  const appClient2 = useAppClientV1();

  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  // Handle form submission (you can perform actions with selectedOptions)
  const handleSubmit = (event: any, eventForm: EventOut, file: File) => {
    event.preventDefault();
    const createForm = event.currentTarget;
    if (createForm.checkValidity() === false) {
      console.log("invalid form");
      event.preventDefault();
      event.stopPropagation();
      for (let element of createForm.elements) {
        if (!element.checkValidity()) {
          console.log(element);
          console.log(
            `Invalid input: ${element.name || element.id}`,
            element.validationMessage
          );
          console.log("Validity state:", element.validity);
          console.log("Current value:", element.value);
        }
      }
      setValidated(true);
    } else {
      let form = convertEventGetToFormData(eventForm, file);
      console.log(form);
      console.log(eventForm);
      postEventForm(appClient2, form)
        .then((e) => {
          toast.success("Event successfully created.");
          navigate(`/event/${e.id}`);
        })
        .catch((error) => toast.error(error));
    }
  };

  return (
    <>
      <Container>
        <h1 className="mt-4">Register an event</h1>
        <EventForm
          initialEventForm={eventForm}
          handleSubmit={handleSubmit}
          validated={validated}
        />
      </Container>
      <Footer />
    </>
  );
}

export default CreateEvent;
