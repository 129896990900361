import { Container } from "react-bootstrap";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { patchEventForm } from "../services/apiService";
import { EventForm } from "../Components/Form";
import { convertEventGetToFormData } from "../util/dataProcessing";
import { toast } from "react-toastify";
import { EventOut } from "../generated-sources/apiv1";
import { useAppClientV1 } from "../util/appClient";
import Footer from "../Components/Footer";
import { useQuery } from "react-query";

function Loading() {
  return <h2> Loading...</h2>;
}
function EditEvent() {
  let { id } = useParams();
  const appClient = useAppClientV1();
  useQuery(
    ["event", id],
    () => {
      if (id) {
        appClient.events
          .eventsEventsRoutesGetEvent(parseInt(id))
          .then(setEventForm);
      }
    },
    { enabled: !!id }
  );

  const [eventForm, setEventForm] = useState<EventOut>();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  // Handle form submission (you can perform actions with selectedOptions)
  const handleSubmit = (event: any, eventForm: EventOut, file: File) => {
    event.preventDefault();
    const createForm = event.currentTarget;
    if (createForm.checkValidity() === false) {
      console.log("invalid form");
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      let form = convertEventGetToFormData(eventForm, file);
      console.log(form);
      console.log(eventForm);
      if (id) {
        patchEventForm(appClient, form, id)
          .then((e) => {
            toast.success("Event successfully updated");
            navigate(`/event/${id}`);
          })
          .catch((error) => toast.error(error));
      }
    }
  };
  return (
    <>
      <Container>
        <h1 className="mt-4">Edit an event</h1>
        {eventForm ? (
          <EventForm
            initialEventForm={eventForm}
            handleSubmit={handleSubmit}
            validated={validated}
          />
        ) : (
          <Loading />
        )}
      </Container>
      <Footer />
    </>
  );
}

export default EditEvent;
