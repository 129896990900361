import {
  Badge,
  Col,
  Container,
  Row,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Calendar, GeoAlt, PencilSquare, TagFill } from "react-bootstrap-icons";
import Map, { Marker } from "react-map-gl";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useAppClientV1 } from "../util/appClient";
import { EventOut, UserOut } from "../generated-sources/apiv1";
import { motion } from "framer-motion";
import { useAuthState } from "../util/auth";
import "mapbox-gl/dist/mapbox-gl.css";
import { Clipboard } from "react-bootstrap-icons";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";
import { useQuery } from "react-query";

interface MarkerLocation {
  latitude: number;
  longitude: number;
}
function EventPage() {
  let { id } = useParams();
  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  const appClient = useAppClientV1();
  const authState = useAuthState();
  const eventQuery = useQuery(
    ["event", id],
    () => {
      if (id) {
        return appClient.events.eventsEventsRoutesGetEvent(parseInt(id));
      }
    },
    { enabled: !!id }
  );
  const event = eventQuery.data;
  const currentUserQuery = useQuery("currentUser", () =>
    appClient.users.eventsUsersRoutesGetCurrentUser()
  );
  const locationQuery = useQuery(
    ["markerLocation", id],
    () => fetchLocation(eventQuery.data),
    { enabled: !!eventQuery.data }
  );

  const fetchLocation = (event: EventOut | undefined) => {
    if (event) {
      const query = `${event.street} ${event?.city} ${event?.country}`.replace(
        " ",
        "%20"
      );
      return fetch(
        `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&limit=1&autocomplete=false&access_token=${MAPBOX_ACCESS_TOKEN}`
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.features && response.features.length > 0) {
            const feature = response.features[0];
            console.log(feature);
            if (
              feature?.geometry?.coordinates &&
              feature?.geometry?.coordinates.length > 0
            ) {
              return {
                latitude: feature.geometry.coordinates[1],
                longitude: feature.geometry.coordinates[0],
              } as MarkerLocation;
            }
          }
        });
    }
  };

 /* const handleDelete = () => {
    if (id) {
      appClient.events
        .eventsEventsRoutesDeleteEvent(parseInt(id))
        .then(() => {
          toast.success("Event deleted with success");
          navigate("/events/by-user");
        })
        .catch((error) => toast.error(error));
    }
  };*/

  const isAdmin = (user: UserOut) => {
    return user.groups.filter((group) => group.name === "Admin").length === 1;
  };

  const renderTooltip = (props: OverlayTriggerRenderProps) => (
    <Tooltip id="button-tooltip" {...props}>
      Copy Address
    </Tooltip>
  );
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {event && (
          <>
            <Container
              fluid
              className=" "
              style={{
                backgroundColor: "#ffffff",
                color: "black",
              }}
            >
              <Container className="pt-5 pb-5">
                <Row>
                  <Col className="my-auto col-12 col-sm-8 order-sm-1 order-2">
                    <div style={{}}>
                      <h1 className="display-1 display-md-3 display-lg-1 fw-bold ">
                        {event.name}{" "}
                        {authState.userId &&
                          (authState.userId === event.user_id ||
                            (currentUserQuery.data &&
                              isAdmin(currentUserQuery.data))) && (
                            <Link
                              to={`/editEvent/${id}`}
                              role="button"
                              style={{ fontSize: "16px" }}
                              className="link-secondary"
                            >
                              {" "}
                              <PencilSquare></PencilSquare>
                              <span> Edit</span>
                            </Link>
                          )}
                      </h1>
                      <br />
                      <div className="fs-4">
                        <div className="mb-2">
                          <Calendar />{" "}
                          <span className="ms-2">
                            {new Date(
                              Date.parse(event.date_from)
                            ).toLocaleDateString("en-En", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </span>
                        </div>
                        <div className="mb-2">
                          <GeoAlt /> <span className="ms-2">{event.city}</span>
                        </div>

                        <div className="mb-3">
                          <TagFill />
                          {event.categories.map((category, tagIndex) => (
                            <span key={tagIndex} className="mb-2">
                              {" "}
                              <Badge>{category.name}</Badge>
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-sm-4 order-sm-2 order-1">
                    <div>
                      <Image
                        src={event.image}
                        fluid
                        rounded
                        style={{ marginBottom: "15px" }}
                      ></Image>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
            <Container
              fluid
              className=" "
              style={{
                backgroundColor: "#f8fafc",
                color: "#272730",
              }}
            >
              <Container className="pt-5 pb-5">
                <Row>
                  <Col sm={8}>
                    <h1>Description</h1>
                    <br></br>
                    <p style={{ whiteSpace: "pre-line" }}>
                      {event.description}
                    </p>
                  </Col>

                  <Col md={4} className="pb-4">
                    <hr></hr>
                    <h5>Address</h5>
                    <Row>
                      <Col>
                        <span>
                          <span>
                            {event.street} <br></br>
                            {event.city} <br></br>
                            {event.country}
                            {"                  "}
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <Clipboard
                                style={{ fontSize: 16, paddingLeft: 4 }}
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    `${event.street} ${event?.city} ${event?.country}`
                                  )
                                }
                              />
                            </OverlayTrigger>
                          </span>
                          <br></br>
                          <br></br>
                        </span>

                        {locationQuery.data && (
                          <Map
                            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                            initialViewState={{
                              ...locationQuery.data,
                              zoom: 14,
                            }}
                            style={{ width: "auto", height: 300 }}
                            mapStyle="mapbox://styles/mapbox/streets-v9"
                          >
                            <Marker
                              longitude={locationQuery.data.longitude}
                              latitude={locationQuery.data.latitude}
                            />
                          </Map>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Container>
            <Footer />
          </>
        )}
      </motion.div>
    </>
  );
}

export default EventPage;
