import { Card } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";

function VerticalCardPlaceholder() {
  return (
    <Card
      style={{ width: "18rem" }}
      className="event-card d-flex flex-column flex-xs-row  flex-lg-column"
    >
      <Card.Img
        variant="top"
        src="placeholder.JPG"
        style={{ height: "30vh", objectFit: "cover" }}
      />
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{" "}
          <Placeholder xs={6} /> <Placeholder xs={8} />
        </Placeholder>
        <Placeholder.Button variant="primary" xs={6} />
      </Card.Body>
    </Card>
  );
}

export default VerticalCardPlaceholder;
