import {
  Badge,
  Button,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";
import { X } from "react-bootstrap-icons";
import { useAuthState } from "../util/auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  EventOut,
  DanceCategoryGet,
  AppClientV1,
} from "../generated-sources/apiv1";
import { AddressAutofill } from "@mapbox/search-js-react";
import type { AddressAutofillRetrieveResponse } from "@mapbox/search-js-core";
import { Search } from "react-bootstrap-icons";
import MiniMap from "./Minimap";
import { useQuery } from "react-query";

interface EventFormProps {
  initialEventForm: EventOut;
  handleSubmit(e: any, event: EventOut, file: File | undefined): void;
  validated: boolean;
}

const configuration = {
  BASE: process.env.REACT_APP_BACKEND_URL,
};

const appClient = new AppClientV1(configuration);
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export function EventForm({
  initialEventForm,
  handleSubmit,
  validated,
}: EventFormProps) {
  const { userId } = useAuthState();
  const [minimapFeature, setMinimapFeature] = useState<any>(null);
  const [eventForm, setEventForm] = useState<EventOut>({
    ...initialEventForm,
    user_id: initialEventForm ? initialEventForm.user_id : userId,
  });
  //todo we should not replace the user id if
  const handleAutofillRetrieve = (
    response: AddressAutofillRetrieveResponse
  ) => {
    if (response) {
      console.log(response.features[0]);
      setMinimapFeature(JSON.stringify(response.features[0]));
    }
  };

  const loadGeoData = (event: EventOut) => {
    const query = `${event.street} ${event?.city} ${event?.country}`.replace(
      " ",
      "%20"
    );
    fetch(
      `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&limit=1&autocomplete=false&access_token=${MAPBOX_ACCESS_TOKEN}`
    )
      .then((response) => response.json())
      .then((response) => {
        if (response?.features && response.features.length > 0) {
          const feature = response.features[0];
          console.log(feature);
          setMinimapFeature(JSON.stringify(feature));
        }
      });
  };
  //For some reason onRetrieve cannot update eventForm object successfully so we use useEffect
  // TODO: Check if we can remove this
  useEffect(() => {
    setEventForm((eventForm) => {
      return {
        ...eventForm,
      };
    });
  }, [minimapFeature]);
  useQuery("events", () =>
    appClient.dancecategories
      .eventsDancecategoriesRoutesListDancategories()
      .then((danceCategories) =>
        setOptions(
          danceCategories
            .sort((a, b) => compareString(a.name, b.name))
            .filter(
              (category) =>
                !initialEventForm.categories.some((c) => c.id === category.id)
            )
        )
      )
  );
  useEffect(() => {
    if (initialEventForm) {
      loadGeoData(initialEventForm);
    }
  }, [initialEventForm]);
  const [file, setFile] = useState<File>();

  // State variable to track selected options
  const [selectedOptions, setSelectedOptions] = useState<DanceCategoryGet[]>(
    initialEventForm.categories
  );
  useEffect(() => {
    setEventForm((eventForm) => {
      return { ...eventForm, categories: selectedOptions };
    });
  }, [selectedOptions]);

  // Options for the multi-select list
  const [options, setOptions] = useState<DanceCategoryGet[]>([]);

  const [selectValue, setSelectValue] = useState<string>("");
  useEffect(() => {
    setSelectValue(selectedOptions.length > 0 ? "validSelect" : "");
  }, [selectedOptions]);

  const compareString = (a: String, b: String) => {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    eventForm && (
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => handleSubmit(e, eventForm, file)}
      >
        <Form.Group className="mb-4 mt-4" controlId="name">
          <Form.Label>Event name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the event name"
            value={eventForm.name}
            onChange={(e) =>
              setEventForm({ ...eventForm, name: e.target.value })
            }
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose an event name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" controlId="description">
          <Form.Label>Event description</Form.Label>
          <Form.Control
            as="textarea"
            rows={8}
            placeholder="Enter the event description"
            value={eventForm.description}
            onChange={(e) =>
              setEventForm({ ...eventForm, description: e.target.value })
            }
            required
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="start_date">
          <Form.Label>Start date</Form.Label>
          <div>
            <DatePicker
              className="form-control"
              showIcon
              selected={
                eventForm.date_from ? new Date(eventForm.date_from) : null
              }
              onChange={(date) => {
                date &&
                  setEventForm({ ...eventForm, date_from: date.toISOString() });
              }}
              placeholderText="Please select a start date"
              required
            />
          </div>
          <Form.Control.Feedback type="invalid">
            Please choose a start date.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" controlId="description">
          <Form.Label>End date</Form.Label>
          <div>
            <DatePicker
              className="form-control"
              showIcon
              selected={eventForm.date_to ? new Date(eventForm.date_to) : null}
              onChange={(date) => {
                date &&
                  setEventForm({ ...eventForm, date_to: date.toISOString() });
              }}
              placeholderText="Please select an end date"
            />
          </div>
        </Form.Group>

        <AddressAutofill
          accessToken={MAPBOX_ACCESS_TOKEN ? MAPBOX_ACCESS_TOKEN : ""}
          onRetrieve={handleAutofillRetrieve}
        >
          <>
            <Form.Group className="mb-4" controlId="street">
              <Form.Label>Address</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Type the event address and select the correct suggestion"
                  autoComplete=" address-line1"
                  value={eventForm.street ? eventForm.street : ""}
                  onChange={(e) =>
                    setEventForm({ ...eventForm, street: e.target.value })
                  }
                  aria-describedby="search-icon"
                  required
                />
                <InputGroup.Text id="search-icon">
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-4" controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                autoComplete="address-level2"
                placeholder="Enter the event city"
                value={eventForm.city ? eventForm.city : ""}
                onChange={(e) =>
                  setEventForm({ ...eventForm, city: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                autoComplete="country-name"
                placeholder="Enter the event country"
                value={eventForm.country ? eventForm.country : ""}
                onChange={(e) =>
                  setEventForm({ ...eventForm, country: e.target.value })
                }
                required
              />
            </Form.Group>
          </>
        </AddressAutofill>
        <MiniMap minimapFeatureString={minimapFeature} />
        <Form.Group
          className="mb-4 align-items-end"
          controlId="formFile"
          style={{ paddingTop: "40px" }}
        >
          <Form.Label>Choose File</Form.Label>
          <Row>
            <Col>
              <Form.Control
                type="file"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  console.log("Change event");
                  if (e.target.files && e.target.files.length > 0) {
                    console.log("update files");
                    console.log(e.target.files);
                    setFile(e.target.files[0]);
                    setEventForm({
                      ...eventForm,
                      image: e.target.files[0].name,
                    });
                  }
                }}
                //required={!initialEventForm.image}
              />
            </Col>

            {initialEventForm.image && (
              <Col md={2}>
                <Image
                  src={
                    file ? URL.createObjectURL(file) : initialEventForm.image
                  }
                  fluid
                />
              </Col>
            )}
          </Row>
        </Form.Group>
        <Form.Group className="mb-4" controlId="formMultiSelect">
          <Form.Label className="mb-3">Dance categories</Form.Label>
          <Form.Text>
            {selectedOptions.map((o, idx) => (
              <Badge key={o.id} className="mx-1">
                {o.name}{" "}
                <X
                  color="red"
                  size={13}
                  onClick={() => {
                    setSelectedOptions(
                      selectedOptions
                        .filter((so) => so.id !== o.id)
                        .sort((a, b) => compareString(a.name, b.name))
                    );
                    setSelectValue("");
                  }}
                />
              </Badge>
            ))}
          </Form.Text>
          <Form.Control
            required
            as="select"
            value={selectValue}
            onChange={(e) => {
              let selectedOptionId: number = parseInt(e.target.value);
              setSelectedOptions(
                selectedOptions
                  .concat(
                    options[options.findIndex((o) => o.id === selectedOptionId)]
                  )
                  .sort((a, b) => compareString(a.name, b.name))
              );
            }}
          >
            <>
              <option value={""} hidden>
                Please select one or more dance categories
              </option>
              <option value={"validSelect"} disabled>
                Please select one or more dance categories
              </option>
              {options
                .filter((value) => !selectedOptions.includes(value))
                .map((option, idx) => (
                  <option
                    key={option.id}
                    value={option.id}
                    id={`category-${option.id}`}
                  >
                    {option.name}
                  </option>
                ))}
            </>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select a dance category.
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    )
  );
}
