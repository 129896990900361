import { AddressMinimap } from "@mapbox/search-js-react";

interface MiniMapProps {
  minimapFeatureString?: string | null;
}

function MiniMap({ minimapFeatureString }: MiniMapProps) {
  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  const minimapFeature = !!minimapFeatureString
    ? JSON.parse(minimapFeatureString)
    : null;
  const shouldDisplay = () => !!minimapFeature && !!MAPBOX_ACCESS_TOKEN;

  return shouldDisplay() ? (
    <div
      id="minimap-container"
      style={{
        height: "180px",
        display: minimapFeature ? "block" : "none",
      }}
    >
      <AddressMinimap
        feature={minimapFeature}
        show={shouldDisplay()}
        satelliteToggle
        accessToken={MAPBOX_ACCESS_TOKEN}
      />
    </div>
  ) : (
    <></>
  );
}

export default MiniMap;
