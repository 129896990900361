/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TokenObtainPairInputSchema } from '../models/TokenObtainPairInputSchema';
import type { TokenObtainPairOutputSchema } from '../models/TokenObtainPairOutputSchema';
import type { TokenRefreshInputSchema } from '../models/TokenRefreshInputSchema';
import type { TokenRefreshOutputSchema } from '../models/TokenRefreshOutputSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Obtain Token
     * @param requestBody 
     * @returns TokenObtainPairOutputSchema OK
     * @throws ApiError
     */
    public ninjaJwtRoutersObtainObtainToken(
requestBody: TokenObtainPairInputSchema,
): CancelablePromise<TokenObtainPairOutputSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/token/token/pair',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Refresh Token
     * @param requestBody 
     * @returns TokenRefreshOutputSchema OK
     * @throws ApiError
     */
    public ninjaJwtRoutersObtainRefreshToken(
requestBody: TokenRefreshInputSchema,
): CancelablePromise<TokenRefreshOutputSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/token/token/refresh',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
