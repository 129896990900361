import { Card, Badge } from "react-bootstrap";
import { Calendar, GeoAlt, TagFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { EventOut } from "../generated-sources/apiv1";

interface VerticalCardProps {
  event: EventOut;
}

function VerticalCard({ event }: VerticalCardProps) {
  return (
    <Card
      style={{ width: "18rem" }}
      className="event-card d-flex flex-column flex-xs-row flex-lg-column"
    >
      <Card.Img
        loading="lazy"
        variant="top"
        src={event.image != null ? event.image : "logo192.png"}
        style={{ height: "30vh", objectFit: "cover" }}
      />
      <Card.Body>
        <Link to={`/event/${event.id}`} className="stretched-link"></Link>

        <Card.Title>{event.name}</Card.Title>
        <div className="mb-2">
          <Calendar />{" "}
          <span className="ms-2">
            {new Date(Date.parse(event.date_from)).toLocaleDateString("en-En", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
        </div>
        <div className="mb-2">
          <GeoAlt /> <span className="ms-2">{event.city}</span>
        </div>
        <div>
          <TagFill />
          {event.categories.map((category, tagIndex) => (
            <span key={tagIndex} className="me-2">
              {" "}
              <Badge>{category.name}</Badge>
            </span>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}

export default VerticalCard;
