/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DanceCategoryGet } from '../models/DanceCategoryGet';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DancecategoriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Dancategories
     * @returns DanceCategoryGet OK
     * @throws ApiError
     */
    public eventsDancecategoriesRoutesListDancategories(): CancelablePromise<Array<DanceCategoryGet>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/dancecategories/',
        });
    }

    /**
     * Get Dancategory
     * @param id 
     * @returns DanceCategoryGet OK
     * @throws ApiError
     */
    public eventsDancecategoriesRoutesGetDancategory(
id: number,
): CancelablePromise<DanceCategoryGet> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/dancecategories/{id}',
            path: {
                'id': id,
            },
        });
    }

}
