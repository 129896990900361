/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventOut } from '../models/EventOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Events
     * @param startDate 
     * @param endDate 
     * @returns EventOut OK
     * @throws ApiError
     */
    public eventsEventsRoutesListEvents(
startDate?: (string | null),
endDate?: (string | null),
): CancelablePromise<Array<EventOut>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/events/',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }

    /**
     * Post Event
     * @param formData 
     * @returns EventOut OK
     * @throws ApiError
     */
    public eventsEventsRoutesPostEvent(
formData: {
image: (string | null);
name: string;
user_id?: (number | null);
date_from: string;
date_to?: (string | null);
description: string;
street: string;
categories: Array<number>;
city?: (string | null);
country?: (string | null);
},
): CancelablePromise<EventOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/events/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * List Events By User
     * @param userId 
     * @returns EventOut OK
     * @throws ApiError
     */
    public eventsEventsRoutesListEventsByUser(
userId: number,
): CancelablePromise<Array<EventOut>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/events/by_user/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Get Event
     * @param id 
     * @returns EventOut OK
     * @throws ApiError
     */
    public eventsEventsRoutesGetEvent(
id: number,
): CancelablePromise<EventOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/events/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Patch Event
     * @param id 
     * @param formData 
     * @returns EventOut OK
     * @throws ApiError
     */
    public eventsEventsRoutesPatchEvent(
id: number,
formData: {
image?: (string | null);
name?: (string | null);
user_id?: (number | null);
date_from?: (string | null);
date_to?: (string | null);
description?: (string | null);
street?: (string | null);
categories?: (Array<number> | null);
city?: (string | null);
country?: (string | null);
},
): CancelablePromise<EventOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/events/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Delete Event
     * @param id 
     * @returns any OK
     * @throws ApiError
     */
    public eventsEventsRoutesDeleteEvent(
id: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/events/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get Test
     * @returns any OK
     * @throws ApiError
     */
    public eventsEventsRoutesGetTest(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/events/test/',
        });
    }

}
