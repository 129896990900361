import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <footer>
      <Container fluid>
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center "
            style={{
              height: "150px",
            }}
          >
            <div className="text-center">
              <p style={{ fontSize: 14 }}>
                <span>Copyright 2024</span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
