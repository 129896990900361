import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AuthDispatcherContext, useAuthState } from "../util/auth";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useAppClientV1 } from "../util/appClient";
import { useQuery } from "react-query";

function NavBar() {
  const authState = useAuthState();
  const authDispatch = useContext(AuthDispatcherContext);
  const navigate = useNavigate();
  const appClient = useAppClientV1();

  const userQuery = useQuery(
    ["user", authState.userId],
    () => appClient.users.eventsUsersRoutesGetCurrentUser(),
    { enabled: !!authState.userId }
  );

  return (
    <Navbar
      bg="light"
      data-bs-theme="light"
      expand="lg"
      collapseOnSelect={true}
    >
      <Container>
        <Navbar.Brand style={{ fontFamily: "cursive" }}>
          <Nav.Link as={Link} to="/" eventKey="1">
            Battle Platform
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" eventKey="1">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/create" eventKey="2">
              Add your event
            </Nav.Link>
            <Nav.Link as={Link} to="/map" eventKey="3">
              Events Map
            </Nav.Link>
          </Nav>

          <Nav>
            {authState?.isLogged && userQuery.data ? (
              <NavDropdown
                title={`Hello ${userQuery.data.username}`}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/events/by-user" eventKey="4">
                  Manage events
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/settings" eventKey="5">
                    Settings
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => {
                    authDispatch({ type: "logout" });
                    toast.success("Logged out with success !");
                    navigate("/");
                  }}
                  eventKey="6"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                {/* Right-aligned item */}
                <Nav.Link as={Link} to="/login" eventKey="7">
                  <span className="btn">Login</span>
                </Nav.Link>
                <Nav>
                  {/* Right-aligned item */}
                  <Nav.Link as={Link} to="/signup" eventKey="8">
                    <span className="btn btn-warning">Sign Up</span>
                  </Nav.Link>
                </Nav>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
