/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserIn } from '../models/UserIn';
import type { UserOut } from '../models/UserOut';
import type { UserPatch } from '../models/UserPatch';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Current User
     * @returns UserOut OK
     * @throws ApiError
     */
    public eventsUsersRoutesGetCurrentUser(): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/me',
        });
    }

    /**
     * Post User
     * @param requestBody 
     * @returns UserOut OK
     * @throws ApiError
     */
    public eventsUsersRoutesPostUser(
requestBody: UserIn,
): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/users/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Patch User
     * @param userId 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public eventsUsersRoutesPatchUser(
userId: number,
requestBody: UserPatch,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
