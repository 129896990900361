import { Badge, Button, Col, Container, Row, Stack } from "react-bootstrap";

import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AppClientV1, EventOut } from "../generated-sources/apiv1";
import VerticalCard from "../Components/VerticalCard";
import VerticalCardPlaceholder from "../Components/VerticalCardPlaceholder";
import { AnimatePresence, motion } from "framer-motion";
import { today } from "../tests/pages/utils";
import HorizontalCard from "../Components/HorizontalCard";
import HorizontalCardPlaceholder from "../Components/HorizontalCardPlaceholder";
import EventsMap from "./EventsMap";
import { Map as MapIcon } from "react-bootstrap-icons";
import { useQuery } from "react-query";
import { Fragment } from "react";
const configuration = {
  BASE: process.env.REACT_APP_BACKEND_URL,
};
const appClient = new AppClientV1(configuration);
export const bgColor = "#E0A75E";
//const targetBgColor = "#F9D689";
function Home() {
  const eventsQuery = useQuery(
    "events",
    () => appClient.events.eventsEventsRoutesListEvents(today().toISOString()),
    {
      onError: (error) => toast.error(`${error}`),
    }
  );
  const pastEventsQuery = useQuery(
    "pastEvents",
    () =>
      appClient.events.eventsEventsRoutesListEvents(
        null,
        today().toISOString()
      ),
    {
      onError: (error) => toast.error(`${error}`),
    }
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center bidirectional-gradient "
            style={{
              fontFamily: "Comic Sans MS",
              color: "black",
              height: "400px",
              // background: `linear-gradient(0deg, ${targetBgColor} 0%, ${bgColor} 100%)`,
            }}
          >
            <div className="text-center">
              <h1>Battle Platform</h1>
              <p style={{ fontSize: 25, paddingBottom: "50px" }}>
                <span>Find and organize your next event with simplicity</span>
              </p>
              <nav id="navbar-example2">
                <Row>
                  <Col>
                    <a href="#upcoming">
                      <Button
                        style={{
                          backgroundColor: "rgb(243, 239, 232)",
                          borderColor: "rgb(243, 239, 232)",
                          color: "black",
                          boxShadow: "0 6px 20px 0 rgba(0,0,0,0.19)",
                        }}
                      >
                        Browse events
                      </Button>
                    </a>
                  </Col>
                  <Col>
                    <Link to={`/create`}>
                      <Button
                        style={{
                          borderColor: "rgb(243, 239, 232)",
                          backgroundColor: "white",
                          borderWidth: "2px",
                          color: "black",
                          boxShadow: "0 6px 20px 0 rgba(0,0,0,0.19)",
                        }}
                      >
                        Add your event
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </nav>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        style={{
          backgroundColor: "#f3efe8",
          borderColor: "#000000",
          borderWidth: 10,
        }}
        fluid
      >
        <Container data-bs-spy="scroll" data-bs-target="#navbar-example2">
          <Row id="upcoming">
            <Col>
              <div
                className="d-flex justify-content-center align-items-center "
                style={{
                  paddingTop: 40,
                  paddingBottom: 40,
                  fontSize: 40,
                }}
              >
                <p>Upcoming events </p>
              </div>
            </Col>
          </Row>
          <div className="d-md-none">
            <EventsListHorizontal
              eventsGroups={groupByDate(eventsQuery.data)}
            />
          </div>
          <div className="d-none d-md-block">
            <EventsListVertical events={eventsQuery.data} />
          </div>
        </Container>
      </Container>
      {pastEventsQuery.isSuccess && pastEventsQuery.data.length > 0 && (
        <Container
          style={{
            borderColor: "#000000",
            borderWidth: 10,
          }}
          fluid
        >
          <Container>
            <Row>
              <Col>
                <div
                  className="d-flex justify-content-center align-items-center "
                  style={{
                    paddingTop: 40,
                    paddingBottom: 40,
                    fontSize: 40,
                  }}
                >
                  <p>Past events </p>
                </div>
              </Col>
            </Row>
            <div className="d-md-none">
              <EventsListHorizontal
                eventsGroups={groupByDate(pastEventsQuery.data)}
              />
            </div>
            <div className="d-none d-md-block">
              <EventsListVertical events={pastEventsQuery.data} />
            </div>
          </Container>
        </Container>
      )}
      <Container fluid className="pb-5" style={{ backgroundColor: "#f8fafc" }}>
        <Col>
          <div
            className="d-flex justify-content-center align-items-center "
            style={{
              paddingTop: 40,
              paddingBottom: 40,
              fontSize: 40,
            }}
          >
            <p>
              Map <MapIcon />
            </p>
          </div>
        </Col>
        <EventsMap />
      </Container>
      <Footer />
    </>
  );
}

interface EventsListProps {
  events: EventOut[] | undefined;
}

const formatDate = (event: EventOut) => {
  const date = new Date(event.date_from);
  const day = date.getDate();
  const month = date.toLocaleDateString("en-US", { month: "long" });
  const weekday = date.toLocaleDateString("en-US", { weekday: "long" });

  return `${day} ${month} • ${weekday}`;
};

const parseDate = (date_str: string) => new Date(Date.parse(date_str));
const groupByDate = (events: EventOut[] | undefined) => {
  if (!events) {
    return null;
  }
  const groupsDict: Map<string, EventOut[]> = events.reduce((groups, event) => {
    const dateKey = new Date(Date.parse(event.date_from)).toDateString();

    if (!groups.has(dateKey)) {
      groups.set(dateKey, []);
    }
    const eventsArray = groups.get(dateKey);
    if (eventsArray) {
      eventsArray.push(event);
    }
    return groups;
  }, new Map<string, EventOut[]>());
  const groupsArray = Array.from(groupsDict.values()).sort(
    (a, b) =>
      parseDate(a[0].date_from).getTime() - parseDate(b[0].date_from).getTime()
  );
  return groupsArray;
};

function EventsListVertical({ events }: EventsListProps) {
  return (
    <AnimatePresence>
      {events && events.length === 0 && (
        <Row>
          <Col>
            <div
              className="d-flex justify-content-center align-items-center "
              style={{
                paddingTop: 40,
                paddingBottom: 40,
                fontSize: 40,
              }}
            >
              <h4>No events found</h4>
            </div>
          </Col>
        </Row>
      )}
      {events && events.length > 0 && (
        <motion.div
          key="events"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <Row
            xs={1}
            md={2}
            lg={3}
            className="gy-5"
            style={{ paddingBottom: 100 }}
          >
            {events.map((event, index) => (
              <Col
                key={index}
                className="d-flex justify-content-center"
                style={{ fontSize: "16px" }}
              >
                <VerticalCard event={event} />
              </Col>
            ))}
          </Row>
        </motion.div>
      )}
      {!events && (
        <motion.div
          key="placeholders"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <Row
            xs={1}
            md={2}
            lg={3}
            className="gy-5"
            style={{ paddingBottom: 100 }}
          >
            {[1, 2, 3].map((index) => (
              <Col
                key={index}
                className="d-flex justify-content-center"
                style={{ fontSize: "16px" }}
              >
                <VerticalCardPlaceholder />
              </Col>
            ))}
          </Row>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
interface EventsListHorizontalProps {
  eventsGroups: EventOut[][] | null;
}
function EventsListHorizontal({ eventsGroups }: EventsListHorizontalProps) {
  return (
    <AnimatePresence>
      {eventsGroups && eventsGroups.length === 0 && (
        <Row>
          <Col>
            <div
              className="d-flex justify-content-center align-items-center "
              style={{
                paddingTop: 40,
                paddingBottom: 40,
                fontSize: 40,
              }}
            >
              <h4>No events found</h4>
            </div>
          </Col>
        </Row>
      )}
      {eventsGroups && eventsGroups.length > 0 && (
        <motion.div
          key="events"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <Stack gap={3} style={{ paddingBottom: 100 }}>
            {eventsGroups.map((events, groupIdx) => (
                <Fragment key={groupIdx}>
                <Badge
                  pill
                  bg="light"
                  text="dark"
                  className="py-2"
                  style={{
                    borderColor: "rgba(0, 0, 0, 0.966)",
                    //width: "fit-content",
                  }}
                >
                  {formatDate(events[0])}
                </Badge>
                {events.map((event, index) => (
                  <Col
                    key={index}
                    className="d-flex justify-content-center"
                    style={{ fontSize: "16px" }}
                  >
                    <HorizontalCard event={event} />
                  </Col>
                ))}
              </ Fragment>
            ))}
          </Stack>
        </motion.div>
      )}
      {!eventsGroups && (
        <motion.div
          key="placeholders"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <Row
            xs={1}
            md={2}
            lg={3}
            className="gy-5"
            style={{ paddingBottom: 100 }}
          >
            <Stack gap={3}>
              {[1, 2, 3].map((index) => (
                <Col
                  key={index}
                  className="d-flex justify-content-center"
                  style={{ fontSize: "16px" }}
                >
                  <HorizontalCardPlaceholder />
                </Col>
              ))}
            </Stack>
          </Row>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
export default Home;
