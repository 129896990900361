import "bootstrap/dist/css/bootstrap.min.css";
//import './App.css';
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateEvent from "./pages/CreateEvent";
import EditEvent from "./pages/EditEvent";
import EventPage from "./pages/Event";
import Home from "./pages/Home";
import { Login } from "./pages/Login";
import Menu from "./pages/Menu";
import MyEvents from "./pages/MyEvents";
import Settings from "./pages/Settings";
import { Signup } from "./pages/Signup";
import { AuthContextProvider } from "./util/auth";
import { ProtectedRoute } from "./util/authGard";
import NavBar from "./Components/NavBar";
import EventsMap from "./pages/EventsMap";
//import 'dotenv/config'
import { QueryClient, QueryClientProvider } from "react-query";
function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <ToastContainer position="top-center" />
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/menu" element={<Menu />}></Route>
            <Route path="/create" element={<CreateEvent />}></Route>
            <Route path="/map" element={<EventsMap />}></Route>
            <Route path="/event/:id" element={<EventPage />}></Route>
            <Route
              path="/editEvent/:id"
              element={
                <ProtectedRoute>
                  <EditEvent />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/events/by-user"
              element={
                <ProtectedRoute>
                  <MyEvents />
                </ProtectedRoute>
              }
            ></Route>
          </Routes>
        </AuthContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
