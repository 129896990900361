import { Card, Badge } from "react-bootstrap";
import { GeoAlt, TagFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { EventOut } from "../generated-sources/apiv1";

interface HorizontalCardProps {
  event: EventOut;
}

function HorizontalCard({ event }: HorizontalCardProps) {
  return (
    <Card className="event-card">
      <div className="row no-gutter">
        <div className="col-4 order-last d-flex pe-4" style={{ padding: 5 }}>
          <Card.Img
            loading="lazy"
            src={event.image != null ? event.image : "logo192.png"}
            style={{
              width: "auto",
              maxWidth: "100%",
              objectFit: "cover",
              borderRadius: "10px",
              margin: "2px",
            }}
          />
        </div>
        <div className="col-8">
          <Card.Body>
            <Link to={`event/${event.id}`} className="stretched-link"></Link>

            <Card.Title>{event.name}</Card.Title>
            <div className="mb-2">
              <GeoAlt /> <span className="ms-2">{event.city}</span>
            </div>
            <div className="text-wrap">
              <TagFill />
              {event.categories.map((category, tagIndex) => (
                <span key={tagIndex}>
                  {" "}
                  <Badge style={{ fontSize: 10 }}>{category.name}</Badge>
                </span>
              ))}
            </div>
          </Card.Body>
        </div>
      </div>
    </Card>
  );
}

export default HorizontalCard;
