import { AppClientV1 } from "../generated-sources/apiv1";
import { useAuthState } from "./auth";



export function useAppClientV1() {
  const { tokenPair } = useAuthState();
  const configuration = tokenPair
    ? {
        BASE: process.env.REACT_APP_BACKEND_URL,
        TOKEN: tokenPair.access,
      }
    : {
        BASE: process.env.REACT_APP_BACKEND_URL,
      };

  return new AppClientV1(configuration);
}
