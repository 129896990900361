import React, {
  createContext,
  useReducer,
  ReactNode,
  useContext,
} from "react";
import { jwtDecode } from "jwt-decode";
import { TokenObtainPairOutputSchema } from "../generated-sources/apiv1";

export interface AuthState {
  isLogged: boolean;
  tokenPair?: TokenObtainPairOutputSchema;
  userId?: number;
}
const initialState: AuthState = { isLogged: false };

export const AuthContext = createContext<AuthState>(initialState);

function noop<T>(e: T) {
  return null;
}
export const AuthDispatcherContext =
  createContext<React.Dispatch<Action>>(noop);

type Action = { type: "login"; payload: AuthState } | { type: "logout" };

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatcherContext.Provider value={dispatch}>
        {children}
      </AuthDispatcherContext.Provider>
    </AuthContext.Provider>
  );
}
type Claims = { userId: number; username: string };
function authReducer(authState: AuthState, action: Action): AuthState {
  console.log("action");
  console.log(action);
  switch (action.type) {
    case "login":
      console.log(action.payload);
      if (action.payload.tokenPair) {
        console.log("decode");
        const claims = jwtDecode<Claims>(action.payload.tokenPair?.access);
        console.log(claims);
        console.log(authState)
        return {
          ...authState,
          tokenPair: action.payload.tokenPair,
          isLogged: true,
          ...claims,
        };
      } else {
        return authState;
      }
    case "logout":
      return { isLogged: false };
    default:
      return authState;
  }
}

export const useAuthState = () => useContext(AuthContext);
export const useAuthDispatch = () => useContext(AuthDispatcherContext);
