import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { useState } from "react";
import Footer from "../Components/Footer";
import { useAuthState } from "../util/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppClientV1 } from "../util/appClient";
import { UserPatch } from "../generated-sources/apiv1";
import { useQuery } from "react-query";

type FormUser = UserPatch;
function Settings() {
  const authState = useAuthState();
  const [formUser, setFormUser] = useState<FormUser>({
    username: "",
    email: "",
  });
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const appClient = useAppClientV1();
  useQuery(
    ["user", authState.userId],
    () =>
      appClient.users.eventsUsersRoutesGetCurrentUser().then((user) => {
        setFormUser({
          username: user.username,
          email: user.email ? user.email : "",
        });
        setValidated(true);
      }),
    { enabled: !!authState.userId }
  );
  /* useEffect(() => {
    console.log(authState.isLogged && authState.userId);
    if (authState.isLogged && authState.userId) {
      appClient.users
        .eventsUsersRoutesGetCurrentUser()
        .then((user) => {
          setFormUser({
            username: user.username,
            email: user.email ? user.email : "",
          });
          setValidated(true);
        })
        .then()

        .catch((error) => {
          toast.error("Erreur");
          console.log(error as string);
        });
    }
  }, [authState.userId]);*/

  const handleSubmit = (event: any, formUser: FormUser) => {
    event.preventDefault();
    const createForm = event.currentTarget;
    if (createForm.checkValidity() === false) {
      console.log("invalid form");
      console.log(createForm);
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      toast.error("Veuillez remplir le formulaire.");
    } else {
      if (authState.userId && authState.tokenPair?.refresh) {
        console.log(formUser);
        appClient.users
          .eventsUsersRoutesPatchUser(authState.userId, formUser)
          .then(() => {
            toast.success("User profile successfully updated");
          })
          .catch((error) => toast.error(error));
      } else {
        toast.error("Please login");
        navigate("/");
      }
    }
  };

  return (
    <>
      <Container style={{ minHeight: "80vh", paddingTop: "15px" }}>
        <Row>
          <Col>
            <h1>My Profile </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e, formUser)}
            >
              <Form.Group className="mb-4 mt-4" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={formUser?.username ? formUser.username : ""}
                  onChange={(e) =>
                    setFormUser({ ...formUser, username: e.target.value })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a username.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your email address"
                  value={formUser?.email ? formUser.email : ""}
                  onChange={(e) =>
                    setFormUser({
                      ...formUser,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Settings;
