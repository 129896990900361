import { useNavigate } from "react-router-dom";
import { useAuthState } from "./auth";
import { toast } from "react-toastify";
import { ReactNode, useEffect } from "react";

export function ProtectedRoute({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { isLogged } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      toast.error("Unauthorized access. Please login.");
      navigate("/");
    }
  }, [isLogged, navigate]);
  return <>{children}</>;
}
