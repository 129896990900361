import { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import {
  Map,
  Source,
  Layer,
  MapMouseEvent,
  Popup,
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";

import type { LayerProps } from "react-map-gl";

import type { MapRef } from "react-map-gl";
import type { GeoJSONSource } from "react-map-gl";
import { appClient } from "../services/apiService";
import { EventOut } from "../generated-sources/apiv1";
import type { FeatureCollection } from "geojson";
import VerticalCard from "../Components/VerticalCard";
import { today } from "../tests/pages/utils";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // Set your mapbox token here

interface PopupInfo {
  latitude: number;
  longitude: number;
  event: EventOut;
}
export default function EventsMap() {
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);
  const mapRef = useRef<MapRef>(null);
  const [geojson, setgeojson] = useState<FeatureCollection>({
    type: "FeatureCollection",
    features: [],
  });

  useQuery(
    "eventsMap",
    () =>
      appClient.events
        .eventsEventsRoutesListEvents(today().toISOString())
        .then((events: EventOut[]) => {
          if (geojson.features.length === 0) {
            loadGeoData(events);
          }
        }),
    {
      onError: (error) => toast.error(`${error}`),
    }
  );

  const loadGeoData = (events: EventOut[]) => {
    if (geojson.features.length === 0) {
      events.forEach((event) => {
        const query =
          `${event.street} ${event?.city} ${event?.country}`.replace(
            " ",
            "%20"
          );
        fetch(
          `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&limit=1&autocomplete=false&access_token=${MAPBOX_ACCESS_TOKEN}`
        )
          .then((response) => response.json())
          .then((response) => {
            if (response?.features && response.features.length > 0) {
              const feature = response.features[0];
              console.log(feature);
              if (
                feature?.geometry?.coordinates &&
                feature?.geometry?.coordinates.length > 0
              ) {
                const featureWithProperties = {
                  ...feature,
                  properties: event,
                };
                console.log("withproperties", featureWithProperties);
                setgeojson((prevData) => ({
                  ...prevData,
                  features: [...prevData.features, featureWithProperties],
                }));
              }
            }
          });
      });
    }
  };

  const onClick = (event: MapMouseEvent) => {
    console.log(event);
    if (!event?.features) {
      return;
    }
    const feature = event.features[0];
    if (!feature) {
      console.log("Close Popup");
      setPopupInfo(null);
      return;
    }

    const clusterId = feature.properties.cluster_id;

    if (mapRef?.current) {
      const mapboxSource = mapRef.current.getSource(
        "earthquakes"
      ) as GeoJSONSource;

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err || !(mapRef?.current && zoom)) {
          return;
        }

        mapRef.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    }
    if (
      feature.layer.id === unclusteredPointLayer.id ||
      event.features.length === 1
    ) {
      console.log("event", feature.properties);
      setPopupInfo({
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
        event: {
          ...feature.properties,
          categories: JSON.parse(feature.properties.categories),
        },
      });
    } else {
      setPopupInfo(null);
    }
  };

  return (
    <Container>
      <Map
        initialViewState={{
          latitude: 46.511859,
          longitude: 6.500642,
          zoom: 6,
        }}
        style={{ width: "100%", height: "75vh" }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        interactiveLayerIds={
          clusterLayer.id && unclusteredPointLayer.id
            ? [clusterLayer.id, unclusteredPointLayer.id]
            : []
        }
        onClick={onClick}
        ref={mapRef}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        <Source
          id="earthquakes"
          type="geojson"
          data={geojson}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
        {popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
            closeOnClick={false}
          >
            <div style={{ marginLeft: -30 }}>
              <VerticalCard event={popupInfo.event} />
            </div>
          </Popup>
        )}
      </Map>
    </Container>
  );
}

export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "earthquakes",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#51bbd6",
      100,
      "#f1f075",
      750,
      "#f28cb1",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: "earthquakes",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "circle",
  source: "earthquakes",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#11b4da",
    "circle-radius": 8,
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff",
  },
};
