import { fireEvent, screen } from "@testing-library/react";

export const changeInputValue = (label: string, value: string) => {
  const input = screen.getByLabelText(label);
  fireEvent.change(input, { target: { value } });
};

export const today = () => {
  return new Date(Date.now());
};
