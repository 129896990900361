import { useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { appClient } from "../services/apiService";
import { AuthDispatcherContext } from "../util/auth";
import { Link } from "react-router-dom";

export function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const authDispatch = useContext(AuthDispatcherContext);
  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (username && password) {
      appClient.auth
        .ninjaJwtRoutersObtainObtainToken({
          username: username,
          password: password,
        })
        .then((tokenPair) => {
          authDispatch({
            type: "login",
            payload: { isLogged: true, tokenPair: tokenPair },
          });
          toast.success("Logged with success !");
          navigate("/");
        })
        .catch(() => toast.error("An error occured. Please try again"));
    }
    setValidated(true);
  };
  return (
    <>
      <Container>
        <Row>
          <Col className="col-md-4 offset-md-4 col-8 offset-2 ">
            <div className="login-card">
              <h1>Login</h1>
              <br></br>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmit(e)}
              >
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your username
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
            <div className="login-card mt-4">
              <span>
                Don't have an account ? <Link to={`/signup`}>Sign up</Link>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
