import { Badge, Card, Col, Container, Row } from "react-bootstrap";

import { Calendar, GeoAlt, TagFill } from "react-bootstrap-icons";

import Footer from "../Components/Footer";
import { useAuthState } from "../util/auth";
import { Link } from "react-router-dom";
import { useAppClientV1 } from "../util/appClient";
import { useQuery } from "react-query";

function MyEvents() {
  const { isLogged, userId } = useAuthState();
  const appClient = useAppClientV1();
  const eventsQuery = useQuery(
    ["eventByUserId", userId],
    () => {
      if (isLogged && userId) {
        return appClient.events.eventsEventsRoutesListEventsByUser(userId);
      }
    },
    { enabled: !!userId }
  );

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ padding: 40 }}
            >
              <h1>My events </h1>
              {eventsQuery.isSuccess && eventsQuery.data?.length === 0 && (
                <div>
                  <br></br>
                  <p>You don't have created any event.</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row
          xs={1}
          md={2}
          lg={3}
          className="gy-5"
          style={{ marginBottom: 100 }}
        >
          {eventsQuery.isSuccess &&
            eventsQuery.data?.map((event, index) => (
              <Col key={index} className="d-flex justify-content-center">
                <Card
                  style={{ width: "18rem" }}
                  className="event-card d-flex flex-column flex-xs-row  flex-lg-column"
                >
                  <Card.Img
                    variant="top"
                    src={event.image != null ? event.image : "logo192.png"}
                    style={{ height: "30vh", objectFit: "cover" }}
                  />
                  <Card.Body>
                    <Link
                      to={`/event/${event.id}`}
                      className="stretched-link"
                    ></Link>

                    <Card.Title>{event.name}</Card.Title>
                    <div className="mb-2">
                      <Calendar />{" "}
                      <span className="ms-2">
                        {new Date(
                          Date.parse(event.date_from)
                        ).toLocaleDateString("en-En", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </span>
                    </div>
                    <div className="mb-2">
                      <GeoAlt /> <span className="ms-2">{event.city}</span>
                    </div>
                    <div>
                      <TagFill />
                      {event.categories.map((category, tagIndex) => (
                        <span key={tagIndex} className="me-2">
                          {" "}
                          <Badge>{category.name}</Badge>
                        </span>
                      ))}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default MyEvents;
