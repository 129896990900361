import { useState } from "react";
import { Form, Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppClientV1 } from "../util/appClient";
import { toast } from "react-toastify";

export function Signup() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const appClient = useAppClientV1();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (username && password) {
      appClient.users
        .eventsUsersRoutesPostUser({
          username: username,
          password: password,
        })
        .then((user) => {
          toast.success("Account successfully created.");
          navigate("/login");
        })
        .catch((error) => window.alert(error));
    }
    setValidated(true);
  };
  return (
    <>
      <Container>
        <Row>
          <Col className="col-md-4 offset-md-4 col-8 offset-2 ">
            <div className="login-card">
              <h1>Sign up</h1>
              <br></br>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmit(e)}
              >
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your username
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="d-grid">
                  <Button variant="primary" type="submit">
                    Login
                  </Button>
                </div>
              </Form>
            </div>
            <div className="login-card mt-4">
              <span>
                Already have an account ? <Link to={`/login`}>Sign in</Link>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
